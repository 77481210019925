import React, { ComponentType } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StudioType, StudioPublicType } from 'app/types';
import { useStudioBoxes, useConfig, useStudioAvailablePlaces } from 'app/hooks';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#333',
    fontSize: '13px',
    backgroundColor: 'white',
    fontWeight: 400,
    lineHeight: '1.4',
    listStyle: 'none',
  },
  linkName: {
    color: '#000',
  },
  link: {
    color: '#427fed',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
  },
  item: {
    '&:nth-of-type(1)': {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
      '&:first-child': {
        fontSize: '14px',
        fontWeight: 500,
      },
    },
  },
  itemCapacity: {
    fontWeight: 500,
    '&:nth-of-type(1)': {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
      '&:first-child': {
        fontSize: '14px',
        fontWeight: 500,
      },
    },
  },
}));

interface colors {
  invalidColor: string;
  validColor: string;
  noDataColor: string;
  soonColor: string;
  brandColor: string;
}

type ListProps = {
  studio: StudioType;
  colors: colors;
  studioLiveData?: StudioPublicType;
};

const googleLink = (studio: StudioType) => {
  let link = 'https://www.google.com/maps/search/?api=1&query=';

  if (studio.streetAddress) {
    link += encodeURIComponent(studio.streetAddress);
  }

  if (studio.addressLocality) {
    link += encodeURIComponent(studio.addressLocality);
  }

  return link;
};

const List: ComponentType<ListProps> = ({ studio, colors, studioLiveData }) => {
  const classes = useStyles();
  const mapDisplay = useConfig((config) => config.mapDisplay);
  const [{ remainingBoxes }, { getDefaultDisplay }] = useStudioBoxes(studio['@id']);
  const [{ remainingPlaces }] = useStudioAvailablePlaces(studio['@id']);

  return (
    <ul className={classes.root}>
      <li className={classes.item}>
        <Link className={classes.linkName} href="/our-boxes">
          {studio.name}
        </Link>
      </li>
      <li className={classes.item}>
        {studio.streetAddress}, {studio.postalCode} {studio.addressLocality}
      </li>
      <li className={classes.item}>Abri de {studio.capacity} places</li>
      {mapDisplay.availablePlaces && studioLiveData && studioLiveData.liveAvailablePlaces === 0 && (
        <li className={classes.itemCapacity} style={{ color: colors.invalidColor }}>
          0 place disponible
        </li>
      )}
      {mapDisplay.availablePlaces && studioLiveData && studioLiveData.liveAvailablePlaces > 0 && (
        <li className={classes.itemCapacity} style={{ color: colors.validColor }}>
          {studioLiveData.liveAvailablePlaces + ' place(s) disponible(s)'}
        </li>
      )}
      {mapDisplay.availablePlaces && (!studioLiveData || studioLiveData.liveAvailablePlaces === -1) && (
        <li className={classes.itemCapacity} style={{ color: colors.noDataColor }}>
          {'Aucune information disponible'}
        </li>
      )}
      {studio.type !== 'collective' && mapDisplay.remainingSubscriptions && remainingBoxes === 0 && (
        <li className={classes.itemCapacity} style={{ color: colors.invalidColor }}>
          0 place disponible
        </li>
      )}
      {studio.type !== 'collective' && mapDisplay.remainingSubscriptions && remainingBoxes > 0 && (
        <li className={classes.itemCapacity} style={{ color: colors.validColor }}>
          {getDefaultDisplay()}
        </li>
      )}
      {studio.type === 'collective' && mapDisplay.remainingSubscriptions && remainingPlaces === 0 && (
        <li className={classes.itemCapacity} style={{ color: colors.invalidColor }}>
          0 abonnement disponible
        </li>
      )}
      {studio.type === 'collective' && mapDisplay.remainingSubscriptions && remainingPlaces > 0 && (
        <li className={classes.itemCapacity} style={{ color: colors.validColor }}>
          {remainingPlaces + ' abonnement(s) disponible(s)'}
        </li>
      )}
      {(studio.streetAddress !== '' || studio.addressLocality !== '') && (
        <li className={classes.item}>
          <a className={classes.link} target="_blank" href={googleLink(studio)} rel="noreferrer">
            Voir dans Google Maps
          </a>
        </li>
      )}
    </ul>
  );
};

export default List;
